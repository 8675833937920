(function(global){
    var script = {
        name : "support-main",
        hash : 'support'
    };

    if(global[script.name]) return; // 중복로딩 차단 
    var detect = vcui.detect;
    var isMobileDevice = detect.isMobileDevice;    
    var $context = $(document); //isMobileDevice ? $('[data-hash="support"]') : $(document);

    var supportHome = {
        loginTooltip : function(){
            var $tooltip = $('.support-home').find('.tooltip-login');
            var $btnClose = $tooltip.find('.btn-tooltip-close');

            $tooltip.on('click', function() {
                lgkorUI.setAcecounter('www.lge.co.kr/acecount/mainLoginClick.do', '/acecount/mainLoginClickm.do');
            });

            $btnClose.on('click', function(e){
                $tooltip.hide();
                e.preventDefault();
            });
        },
        slide : {
            activeClass : ".slick-initialized",
            controls : {
                play : $(document).find('.btn-play')
            },
            service : {
                el : {
                    slider : $('.main-service-slider')
                },                
                config : {
                    infinite: true,
                    autoplay: false,
                    arrows : false,
                    dots : false,
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    cssEase: 'cubic-bezier(0.33, 1, 0.68, 1)',
                    speed: 150,
                    touchThreshold: 100
                }, 
            },
            notice : {
                el : {
                    slider : $('.notice-slider')
                },                
                config : {
                    infinite: true,
                    autoplay: true,
                    arrows : false,
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    vertical:true,
                    verticalSwiping: true,
                    cssEase: 'cubic-bezier(0.33, 1, 0.68, 1)',
                    speed: 150,
                    touchThreshold: 100
                },
            },
            related : {
                el : {
                    slider : $('.related-slider')
                },                
                config : {
                    infinite: true,
                    autoplay: true,
                    arrows : false,
                    dots : false,
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    cssEase: 'cubic-bezier(0.33, 1, 0.68, 1)',
                    speed: 150,
                    touchThreshold: 100
                },
            },
            reserv : {
                el : {
                    slider : $('.reserv-slider')
                },                
                config : {
                    infinite: true,
                    autoplay: false,
                    arrows : false,
                    dots : true,
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    cssEase: 'cubic-bezier(0.33, 1, 0.68, 1)',
                    speed: 150,
                    touchThreshold: 100
                },
            },
            resize : function(){
                var self = this;
            },
            init : function(){
                var self = this;

                $('[data-auto-type]').each(function(idx, item){
                    $(item).on('init', function(event, slick){
                        var $this = $(this);
                        var _type = $this.data('autoType');
                        var $container = $this.closest('[data-role="slide-container"]');
                        var _count = $('<div class="slide-count"><strong class="curr"></strong><span class="total"></span></div>');
                        var _button = $('<button type="button" class="btn-play"><span class="blind">정지</span></button>');
                        var _prev = $('<button type="button" class="btn-prev"><span class="blind">이전 슬라이드</span></button>');
                        var _next = $('<button type="button" class="btn-next"><span class="blind">다음 슬라이드</span></button>');

                        if(!slick.options.autoplay){
                            _button = $('<button type="button" class="btn-play pause"><span class="blind">재생</span></button>');
                        }
    
                        if( _type == "button") {
                            _count.prepend(_prev);
                            _count.append(_next);
                            _count.prepend(_button);
                            $this.append(_count);
                        }
                        // if( _type == "dot") {
                        //     var $dotCont = $this.next('.slick-controls');
                        //     $dotCont.append(_button);
                        // }


                        if(slick.$slides.length > slick.options.slidesToScroll) {
                            $container.find('.slide-count').show();
                            $container.find('.btn-play').show();
                        } else {
                            $container.find('.slide-count').hide();
                            $container.find('.btn-play').hide();
                        }

                        $this.on('click', '.btn-play', function(e){
                            var _this = $(this);
                            if (_this.hasClass("pause")) {
                                _this.removeClass("pause");
                                _this.find('span').text("정지");
                                $this.slick("slickPlay");
                            } else {
                                _this.addClass("pause");
                                _this.find('span').text("재생");
                                $this.slick("slickPause");
                            }
                        });

                        $this.on('click', '.btn-prev', function(){
                            $this.slick("slickPrev");
                        });
                        $this.on('click', '.btn-next', function(){
                            $this.slick("slickNext");
                        });
                    });
                });

                


                //히어로 슬라이드
                $(self.service.el.slider).not(self.activeClass)
                .on('init', function(event, slick){
                    // autoplay
                    // if(!self.service.config.autoplay) {
                    //     $(self.service.el.slider).find('.btn-play').addClass('pause');
                    // }
                    
                    $(self.service.el.slider).find('.slide-count strong.curr').html('<em class="blind">현재 슬라이드</em>' + 1);
                    $(self.service.el.slider).find('.slide-count span.total').html(' / <em class="blind">총 슬라이드</em>' + slick.slideCount);
                })
                .on('beforeChange', function(event, slick, currentSlide, nextSlide){
                    $(self.service.el.slider).find('.slide-count strong.curr').html('<em class="blind">현재 슬라이드</em>' + (nextSlide+1));
                    $(self.service.el.slider).find('.slide-count span.total').html(' / <em class="blind">총 슬라이드</em>' + slick.slideCount);
                })
                .slick(self.service.config);


                //공지사항 슬라이드
                $(self.notice.el.slider).not(self.activeClass)
                .on('init', function(event, slick){
                    // autoplay
                    // if(!self.notice.config.autoplay) {
                    //     $(self.notice.el.slider).find('.btn-play').addClass('pause');
                    // }

                    $(self.notice.el.slider).find('.slide-count strong.curr').html('<em class="blind">현재 슬라이드</em>' + 1);
                    $(self.notice.el.slider).find('.slide-count span.total').html(' / <em class="blind">총 슬라이드</em>' + slick.slideCount);
                })
                .on('beforeChange', function(event, slick, currentSlide, nextSlide){
                    $(self.notice.el.slider).find('.slide-count strong.curr').html('<em class="blind">현재 슬라이드</em>' + (nextSlide+1));
                    $(self.notice.el.slider).find('.slide-count span.total').html(' / <em class="blind">총 슬라이드</em>' + slick.slideCount);
                })
                .slick(self.notice.config);

                //최근 이용 서비스
                if(!!$(self.reserv.el.slider).length){
                    $(self.reserv.el.slider).not(self.activeClass)
                    .on('init', function(event, slick){
                        //BTOCSITE-71268 고객지원 > 웹접근성
                        setTimeout(function(){
                            $(self.reserv.el.slider).find('.slick-dots button').attr('tabindex', '0');
                        }, 500);
                    })
                    .slick(self.reserv.config);
                }


                // 가전꿀팁 슬라이드
                $(self.related.el.slider).not(self.activeClass)
                .on('init', function(event, slick){
                    // autoplay
                    // if(!self.related.config.autoplay) {
                    //     $(self.related.el.slider).find('.btn-play').addClass('pause');
                    // }

                    $(self.related.el.slider).find('.slide-count strong').html('<em class="blind">현재 슬라이드</em>' + 1);
                    $(self.related.el.slider).find('.slide-count span').html(' / <em class="blind">총 슬라이드</em>' + slick.slideCount);
                })
                .on('beforeChange', function(event, slick, currentSlide, nextSlide){
                    $(self.related.el.slider).find('.slide-count strong').html('<em class="blind">현재 슬라이드</em>' + (nextSlide+1));
                    $(self.related.el.slider).find('.slide-count span').html(' / <em class="blind">총 슬라이드</em>' + slick.slideCount);
                })
                .slick(self.related.config);


            },
            refresh : function(){
                $context.find('.slick-initialized').slick('refresh');
            }
        },
        modal : {
            el : {
                modal : '<div class="ui_modal_wrap init-type" style="position:fixed; z-index:9000; top:0; left:0; width:100%; height:100%;"/>',
                popup : '.popup-init',
                check : '[data-role="today-cookie-check"]',
                close : '.btn-close'
            },
            init : function(){
                var self = this;                
                var $popup = $(self.el.popup);

                if($popup.length ) {
                    $popup.each(function(v, i){
                        var $this = $(this);
                        var _id = $this.attr('id');

                        if( lgkorUI.cookie.getCookie(_id) == "done") {
                            $this.addClass('hidden');
                        }
                    })
                    $popup.not('.hidden').addClass('active').attr('tabindex', '0');

                    if( $(".ui_modal_wrap.init-type").length == 0 && $popup.filter('.active').length ) {
                        $popup.filter('.active').wrapAll(self.el.modal);
                        $popup.filter('.active').stop().fadeIn();
                        $popup.filter('.active').first().focus();

                        if( !vcui.detect.isMobileDevice) {
                            $popup.filter('.active').not('.mCustomScrollbar').find('.pop-conts').mCustomScrollbar();
                            $popup.filter('.active').not('.mCustomScrollbar').find('.video-figure').mCustomScrollbar();
                        }
                    }
                }



                $popup.find(self.el.close).on('click', function(e){
                    var $this = $(this);
                    var $curModal = $this.closest('.popup-init');
                    var $modalWrap = $this.closest('.ui_modal_wrap');
                    var $dimm = $modalWrap.find('.ui_modal_dim');
                    var $check = $curModal.find(self.el.check).find('input:checkbox');
                    var _id = $curModal.attr('id');

                    if( $check.prop('checked')) {
                        lgkorUI.cookie.setCookie(_id, "done", 1);
                    }
                    
                    if( $modalWrap.find('.popup-init.active').length == 1) {
                        $modalWrap.stop().fadeOut(function(){
                            //$popup.unwrap();
                            $curModal.hide().removeClass('active');
                            $('html').css('overflow', 'visible');
                        });
                    } else {
                        $curModal.stop().fadeOut(function(){
                            $(this).removeClass('active');
                        })
                    }
                    e.preventDefault();
                });

                var $elFocus = $('.ui_modal_wrap.init-type').find('a, button, input, textarea').filter(':visible');

                $('.ui_modal_wrap.init-type .ui_modal_dim').on('click', function(e){
                    e.preventDefault();
                    e.stopPropagation();
                })

                // $elFocus.first().css('color', 'red');
                // $elFocus.last().css('color', 'blue');

                $popup.filter('.active').first().on('keydown', function(e){
                    if( e.shiftKey && e.keyCode == 9) {
                        if( $(e.target).is('.popup-init') ) {
                            e.preventDefault();
                            $elFocus.last().focus();
                        }
                    }
                })

                $elFocus.last().on('keydown', function(e){
                    if( !e.shiftKey && e.keyCode == 9) {
                        e.preventDefault();
                        $elFocus.first().focus();
                    }
                })
            }
        },
        keyword: {
            el: {
                searchWrap: '.ui_search',
                searchInput: '.ui_search #search',
                recentlyWrap: '.recently-keyword',
                popularWrap: '.popular-keyword',
                recommendWrap: '.search-recommend',
                autocompleteWrap: '.autocomplete-box',
                supportSearchPopup: '#supportSearchPopup'

            },
            keywordSearch: function(text){
                
                var url = $('article#supportSearchPopup').find('.ui_search').data('searchUrl');
                text = text.replace(/(<([^>]+)>)/ig,""); //BTOCSITE-5089
                text = text.replace(/<\/([^>]+)/ig,""); //BTOCSITE-5089
                   $('article#supportSearchPopup').find('.btn-close').click();
                   location.href = url + encodeURI(text);
                   
            },
            setRecentKeyword: function(){
                var keywordCookie = lgkorUI.cookie.getCookie('LG_SupportKeyword');
                var $recentlyKeyword = $('article#supportSearchPopup').find('.recent_search_word');
                var recentlyListTemplate = '<li><a href="">{{keyword}}</a><button type="button" ><span class="blind">삭제</span></button></li>';

                $recentlyKeyword.find('ul').empty();
                if (keywordCookie && keywordCookie.length > 0) {
                    arr = keywordCookie.split(',');
                    if (arr.length) {
                        arr.forEach(function(item) {                        
                            var html = recentlyListTemplate.replace('{{keyword}}', item.toString());
                            $recentlyKeyword.find('ul').append(html);
                        });
                    }
                }
                if ($recentlyKeyword.find('li').length <= 0) {
                    $recentlyKeyword.hide();
                };
            },
            init: function() {
                if (!$context.find('.ui_search').length) return;

                var self = this;
                var $searchWrap = $context.find(self.el.searchWrap);
                var $searchInput = $context.find(self.el.searchInput);
                var $recentlyWrap = $context.find(self.el.recentlyWrap);
                var $popularWrap = $context.find(self.el.popularWrap);
                var $autocompleteWrap = $context.find(self.el.autocompleteWrap);
                var $recommendWrap = $context.find(self.el.recommendWrap);
                var $supportSearchPopup = $context.find(self.el.supportSearchPopup);
                var $recentlyKeyword = $supportSearchPopup.find('.recent_search_word');
                var $popularKeyword = $supportSearchPopup.find('.popular_search_word');

                var url = $searchWrap.data('searchUrl');

                $searchInput.on('keyup', function(e) {
                    if (e.keyCode == 13) {  
                        e.preventDefault();
                        var _value = $searchInput.val();
                        lgkorUI.setAcecounter('www.lge.co.kr/acecount/mainSearchClick.do', '/acecount/mainSearchClickm.do');
                        _value = _value.replace(/(<([^>]+)>)/ig,""); //BTOCSITE-5089
                        _value = _value.replace(/<\/([^>]+)/ig,""); //BTOCSITE-5089
                        location.href = url + encodeURI(_value)
                    }
                });
                $searchInput.on('focus', function(e) {
                    $recommendWrap.hide();
                });

                $searchWrap.find('.btn-search').on('click', function() {
                    var _value = $searchInput.val();
                    if(_value){
                        _value = _value.replace(/(<([^>]+)>)/ig,""); //BTOCSITE-5089
                        _value = _value.replace(/<\/([^>]+)/ig,""); //BTOCSITE-5089
                    }else{
                        var _value = $searchInput.data('suggested');
                    }
                    lgkorUI.setAcecounter('www.lge.co.kr/acecount/mainSearchClick.do', '/acecount/mainSearchClickm.do');
                    location.href = url + encodeURI(_value)
                });

                $searchWrap.on('keywordClick', function() {
                    var _value = $searchInput.val();
                    _value = _value.replace(/(<([^>]+)>)/ig,""); //BTOCSITE-5089
                    _value = _value.replace(/<\/([^>]+)/ig,""); //BTOCSITE-5089

                    // BTOCSITE-15280 고객지원 Main 통합 검색의 자동 완성 기능 추가 요청
                    lgkorUI.recentlyKeyword.addCookie(_value);

                    location.href = url + encodeURI(_value)
                });

                $autocompleteWrap.on('click', 'a', function() {
                    lgkorUI.setAcecounter('www.lge.co.kr/acecount/mainModelClick.do', '/acecount/mainModelClickm.do');
                });

                $recentlyWrap.on('click', 'a', function() {
                    lgkorUI.setAcecounter('www.lge.co.kr/acecount/mainRecentClick.do', '/acecount/mainRecentClickm.do');
                });

                $popularWrap.on('click', 'a', function() {
                    lgkorUI.setAcecounter('www.lge.co.kr/acecount/mainPopularClick.do', '/acecount/mainPopularClickm.do');
                });
                // BTOCSITE-15280 고객지원 Main 통합 검색의 자동 완성 기능 추가 요청
                $searchWrap.search({
                    template: {
                        autocompleteList: '<li><a href="#{{input}}">{{#raw text}}</a></li>',
                        recentlyList: '<li><a href="#">{{keyword}}</a><button type="button" class="btn-delete"><span class="blind">삭제</span></button></li>',
                        keywordList: '<li><a href="#">{{keyword}}</a></li>'
                    }
                });
                // BTOCSITE-15280 고객지원 Main 통합 검색의 자동 완성 기능 추가 요청
                $searchWrap.on('autocompleteonmain', function(e, param, url, callback) {
                    lgkorUI.requestAjaxData(url, param, function(result) {
                        callback(result);
                    });
                });
                

                //검색 팝업
                self.setRecentKeyword();

                $supportSearchPopup.find('.btn-close').on('click', function(e){
                    $recommendWrap.show();

                    //BTOCSITE-71268 고객지원 > 웹접근성
                    $supportSearchPopup.attr('tabindex', '-1').focusout();
                    setTimeout(function(){
                        $('.keyword-search-wrap #search').focus();
                    }, 500);
                });

                $recentlyKeyword.on("click","a", function(e){ 
                    e.preventDefault();
                    var text = e.target.innerHTML.replace("#","").split('<')[0];
                    self.keywordSearch(text);
                });

                $recentlyKeyword.find('input').on('keyup', function(e){
                    if (e.keyCode == 13){
                        e.preventDefault();
                        $supportSearchPopup.find('.btn-search').click();        
                    }
                });

                $recentlyKeyword.find('.btn-search').on('click', function(e){
                    var input = $supportSearchPopup.find('input').val();
                    self.keywordSearch(input);
                    lgkorUI.$recentlyKeyword.addCookie(input);
                });

                $recentlyKeyword.find('.del_all').on('click', function(e){
                    var arr = Array.from($('.recent_search_word').find('ul li a'))
                    arr = arr instanceof Array ? arr : [];
                    arr.forEach(function(item) {                 
                        lgkorUI.cookie.deleteCookie('LG_SupportKeyword', item.innerHTML);
                    });
                    $recentlyKeyword.find('ul li').remove();
                    $recentlyKeyword.hide()
                });

                $recentlyKeyword.on('click', 'li button',function(e){
                    var deleteTarget = $(this).parent().find('a').html();
                    lgkorUI.cookie.deleteCookie('LG_SupportKeyword', deleteTarget);
                    $(this).parent('li').remove();
                    if ($recentlyKeyword.find('li').length <= 0) {
                        $recentlyKeyword.hide();
                    };
                });

                // BTOCSITE-81830 [검색] 고객지원 페이지내 검색어 집계 
                $recentlyKeyword.on('click', 'li a', function() {
                    var keyword = $(this).text();

                    lgkorUI.sendKeyword(keyword, '3', '1', 'supportSearch');
                });
                $popularKeyword.on('click', 'li a', function() {
                    var keyword = $(this).text();

                    lgkorUI.sendKeyword(keyword, '3', '1', 'supportSearch');
                });
            }
        },
        center: {
            el : {
            },
            
            // 현재 위치 검색
            _setCurrentSearch: function(init) {
                var self = this;
                var wpid;
                var isClose = false;
                var searchCurrentSearch = function() {
                    try {
                        if (isApp() && vcui.detect.isAndroid) {
                            var appGeoAgree = android.getLocationActive();
                            if (appGeoAgree == "Y") {
                                android.getLocation("setAppLocation");
                            } else {
                                if (navigator.geolocation) {
                                    // 안드로이드 핸드폰 설정에서 앱의 위치권한을 막을시 권한을 얻기위한 무한 대기로 watchPosition로 변경 ( timeout은 권한을 얻기 위한 시간은 제외됨 )
                                    wpid = navigator.geolocation.watchPosition(
                                        function (e) {
                                            if (!isClose) {
                                                isClose = true;
                                                onSuccessGeolocation(e);
                                                navigator.geolocation.clearWatch(wpid);
                                            }
                                        },
                                        function (e) {
                                            if (!isClose) {
                                                isClose = true;
                                                onErrorGeolocation(e);
                                                navigator.geolocation.clearWatch(wpid);
                                            }
                                        }, {timeout: 3000, frequency: 10000}
                                    );

                                    setTimeout(function () {
                                        if (!isClose) {
                                            isClose = true;
                                            onErrorGeolocation();
                                            navigator.geolocation.clearWatch(wpid);
                                        }
                                    }, 4000);
                                } else {
                                    onErrorGeolocation();
                                }
                            }
                        } else {
                            navigator.geolocation.getCurrentPosition(function (pos) {
                                onSuccessGeolocation(pos);
                            }, function (e) {
                                onErrorGeolocation(e);
                            });
                        }
                    } catch (e) {
                        setAppLocation("37.5235644,127.0395764");
                    }
                };
                var onSuccessGeolocation = function(pos){
                    self.latitude = pos.coords.latitude;
                    self.longitude = pos.coords.longitude;

                    self.searchResultMode = init ? false : true;
                    // self.schReaultTmplID = "currentSearch";

                    self._loadStoreData();
                    // self._showMap(true);
                    // !init && self._showResultLayer();
                };
                var onErrorGeolocation = function (e) {
                    var errorCodes = {
                        code1: "위치정보 권한이 없습니다.",
                        code2: "위치정보 오류가 있습니다.",
                        code3: "제한된 시간이 지났습니다.",
                    };

                    var msg;
                    if (e) {
                        msg = errorCodes["code" + e.code];
                    } else {
                        msg = "현재 위치를 찾을 수 없습니다.";
                    }

                    lgkorUI.alert(msg, {
                        title: '현재 위치 정보',
                        typeClass: 'type2',
                        ok: function() {
                            if (init) {
                                self.searchResultMode = false;

                                self.latitude = self.defaultLatitude;
                                self.longitude = self.defaultLongitude;

                                if (self.isLogin) {
                                    self._setUserAdressSearch(true);
                                } else {
                                    self._loadStoreData();
                                }
                            }
                        }
                    });
                };
                setAppLocation = function(currentLocation){
                    if (currentLocation == '') currentLocation = '37.55401,126.97486';
                    
                    var arrLocation = currentLocation.split(',');
                    self.latitude = arrLocation[0];
                    self.longitude = arrLocation[1];

                    self.searchResultMode = init ? false : true;
                    // self.schReaultTmplID = "currentSearch";

                    self._loadStoreData();    
                    // !init && self._showResultLayer();
                };

                // 앱 위치 권한 콜백
                useGps = function(appGeoAgree) {
                    if(isApp()){
                        if (appGeoAgree=='Y'){
                            if(vcui.detect.isIOS) {
                                var jsonString= JSON.stringify({
                                    command : "getGPS",
                                    callback : "setAppLocation"
                                });
                                webkit.messageHandlers.callbackHandler.postMessage(jsonString);   
                            } else {
                                android.getLocation('setAppLocation');
                            }
                            //searchCurrentSearch();
                            //setAppLocation(android.getLocation());	
                        } else {
                            if (!init) {
                                lgkorUI.alert('', {
                                    title: '설정 > 앱권한에서 위치정보 이용동의를 하셔야 이용 가능합니다.'
                                }, self.$searchCurrentButton[0]);
                            } else {
                                self.searchResultMode = false;

                                self.latitude = self.defaultLatitude;
                                self.longitude = self.defaultLongitude;
                                
                                self._loadStoreData();
                            }	
                            //setAppLocation('37.55401,126.97486');	    	        			
                        }
                    } 
                }

                var obj ={
                    title:'센터찾기 위치 정보 수집 이용 동의', 
                    typeClass:'type2', 
                    okBtnName: '동의',
                    cancelBtnName: '동의 안함',
                    ok : function (){
                        setTimeout(function () {
                            window.sessionStorage.setItem("findCenterMapAgree", "Y");
                            searchCurrentSearch();
                        }, 300);
                    },
                    cancel: function() {
                        lgkorUI.alert('현재 위치를 찾을 수 없습니다.', {
                            title: '현재 위치 정보',
                            typeClass: 'type2',
                            ok: function() {
                                if (init) {
                                    self.searchResultMode = false;

                                    self.latitude = self.defaultLatitude;
                                    self.longitude = self.defaultLongitude;
                                    
                                    if (self.isLogin) {
                                        self._setUserAdressSearch(true);
                                    } else {
                                        self._loadStoreData();
                                    }
                                }
                            }
                        });
                    }};

                // BTOCSITE-34955 위치정보 제공 동의서 수정 및 링크 추가 요청 건
                var desc = '<p style="text-align:left;">LG전자 주식회사(이하 &apos;회사&apos;라 합니다)는 고객님께서 현재 계신 위치에서 직선 거리 기준으로 가장 가까운 센터를 안내하기 위하여 위치정보를 수집하고 있습니다. <br><br>또한 상기 서비스 제공 후 즉시 폐기되며, 별도 저장되지 않습니다.<br><br>본 서비스 이용을 위해서는 <a href="https://kr.m.lgaccount.com/customer/terms_detail?country=KR&language=ko-KR&terms_svcCode=SVC709&terms_type=A_ITG_LBS&terms_publish=Y&division=noticeterms" style="text-decoration: underline;" target="_blank" data-app-target="out">&apos;LG전자 위치기반서비스 이용약관&apos;</a>의 동의가 필요합니다.<br><br>고객님의 현재 계신 위치 정보 제공 및 LG전자 위치기반서비스 이용약관에 동의하시겠습니까?</p>';

                var findCenterMapAgree = window.sessionStorage.getItem("findCenterMapAgree");
                if (findCenterMapAgree && findCenterMapAgree == "Y") {
                    searchCurrentSearch();
                } else {
                    lgkorUI.confirm(desc, obj);
                }
            },
            _loadStoreData: function(seq){
                var self = this;
                var param = {
                    latitude:self.latitude,
                    longitude:self.longitude
                }
    
                if (seq) param = $.extend(param, {
                    seq: seq
                });
    
                var url = "/support/center/selectCenterXy.lgajax";
                lgkorUI.showLoading();
                lgkorUI.requestAjaxDataPost(url, param, function(result){

                    var data = result.data.seqFindCenter[0];
                    self.setCenterDetail(data);
                    
                    lgkorUI.hideLoading();
                });
            },
            setCenterDetail : function(data){
                var self = this;
                var centerUrl = "/support/find-service-center-" + data.shopID;
                var $selectBox = self.$svcCenterWrap.find(".ui_selectbox");
                var val = $selectBox.find('option:selected').val();

                if(val == "현재 위치로 찾기") {
                    $selectBox.find("option:first-child").text(data.shopName);
                    $selectBox.find("option:first-child").attr('value', data.shopName);
                    $selectBox.find("option:first-child").attr('data-shop-id', data.shopID);
                    $selectBox.vcSelectbox('update');
                    $selectBox.vcSelectbox('selectedIndex', 0);
                    self.$svcCenterWrap.find('.btn-center-detail').attr('href', centerUrl);
                    self.$svcCenterWrap.find('.btn-center-detail').attr('title', data.shopName);
                }

            },
            init : function(){
                var self = this;

                //센터 상세보기
                self.$svcCenterWrap = $context.find('.service-center-wrap');
                var selectBox = self.$svcCenterWrap.find(".ui_selectbox").vcSelectbox('instance');
                $(selectBox).siblings('.ui-selectbox-wrap').find('.ui-select-button').attr('data-contents', '선택 열기');

                self.$svcCenterWrap.find(".ui_selectbox").on("change", function(e){
                    var $this = $(this);
                    var val = $this.find('option:selected').val();

                    if(val == "현재 위치로 찾기") {
                        self._setCurrentSearch();
                    }
                }).on('selectboxopen', function(e){
                    $(selectBox).siblings('.ui-selectbox-wrap').find('.ui-select-button').attr('data-contents', '선택 닫기');
                }).on('selectboxclose', function(e){
                    $(selectBox).siblings('.ui-selectbox-wrap').find('.ui-select-button').attr('data-contents', '선택 열기');
                });
                
                self.$svcCenterWrap.find(".btn-center-detail").on("click", function(e){
                    e.preventDefault();
                    e.stopPropagation();

                    var windowHeight = $(window).height();
                    var currentUrl = $(this).attr("href");

                    window.open(currentUrl, "_blank", "width=1070, height=" + windowHeight + ", location=no, menubar=no, status=no, toolbar=no, scrollbars=1");
                });
            }
        },
        requestServiceList: function(_data){
            var self = this;
            
            var serviceTmpl = 
            '<div class="list-wrap">'+
            '<ul data-contents="{{modelCode}}">'+
            '{{#each (item, index) in data.serviceMenuList}}' +
                '{{#if item.name == "출장 서비스 예약"}}'+
                    '<li class="item"><a href="{{item.url}}" class="icon-reserv">출장 서비스 예약</a></li>'+
                '{{#elsif item.name == "예약 조회/변경"}}'+
                    '<li class="item"><a href="{{item.url}}" class="icon-result">예약 조회/ 변경</a></li>'+
                '{{#elsif item.name == "서비스 전문 상담"}}'+
                    '<li class="item"><a href="{{item.url}}" class="icon-contact">서비스 전문 상담</a></li>'+
                '{{#elsif item.name == "매뉴얼/소프트웨어"}}'+
                    '<li class="item"><a href="{{item.url}}" class="icon-manual">매뉴얼/<br>소프트웨어</a></li>'+
                '{{#elsif item.name == "센터 방문 예약"}}'+
                    '<li class="item"><a href="{{item.url}}" class="icon-visit">센터 방문 예약</a></li>'+
                '{{#elsif item.name == "케어용품/소모품"}}'+
                    '<li class="item"><a href="{{item.url}}" class="icon-goods">케어용품/소모품</a></li>'+
                '{{#elsif item.name == "가전 세척"}}'+
                    '<li class="item"><a href="{{item.url}}" class="icon-wash">가전 세척</a></li>'+
                '{{#elsif item.name == "이전 설치"}}'+
                    '<li class="item"><a href="{{item.url}}" class="icon-removal">이전 설치</a></li>'+
                '{{/if}}'+
            '{{/each}}' +
            '</ul>'+
            '</div>';


            var suppliesTmpl = 
            '<div class="supplies-title-wrap" data-contents="{{modelCode}}">'+
                '<h5 class="supplies-title">내 제품에 딱 맞는 소모품</h5>'+
                '<button type="button" id="supplies-btn" class="btn-link supplies-btn"'+
                    'onclick="location.href=\'search/resultUnit?force=false&unit=disposable&search={{modelCode}}\'"><span>더보기</span></button>'+
            '</div>'+
            '<div class="supplies-list">'+
            '<ul data-contents="{{modelCode}}">'+
            '{{#each (item, index) in suppliesList}}' +
            '<li class="item">'+
                '<p class="prd-img">'+
                    '<a href="{{item.url}}"><span class="blind">제품상세 바로가기</span></a>'+
                    '{{#if item.buyDisposableCnt}}'+
                        '<em class="flag">{{item.buyDisposableCnt}}회 구매</em>'+
                    '{{/if}}'+
                    
                    '{{#if item.promotionFlag}}'+
                        '<em class="flag red">프로모션</em>'+
                    '{{/if}}'+

                    '<img src="{{item.imageUrl}}" alt="{{item.imageAlt}}">'+
                '</p>'+
                '<p class="prd-name"><span class="blind">제품명</span>{{item.title}}</p>'+
                '<p class="model-code"><span class="blind">모델코드</span>{{item.sku}}</p>'+
                '<p class="price"><span class="blind">제품가격</span>{{item.price}}</p>'+
                '<p class="cart" data-contents="{{modelCode}}-{{item.sku}}">'+
                    '{{#if item.soldoutFlag == "N"}}'+
                        '<button type="button" class="btn-cart" data-id="{{item.modelId}}" data-sku="{{item.sku}}"><span class="blind">장바구니 담기</span></button>'+
                    '{{#else}}'+
                        '<span class="soldoutFlag">일시품절<span>'+
                    '{{/if}}'+
                '</p>'+
            '</li>'+
            '{{/each}}' +
            '</ul>'+
            '</div>';


            var recommendTmpl = 
            '<div class="recommend-title-wrap">'+
                '<h5 class="recommend-title">내 제품에 꼭 필요한 정보</h5>'+
                '<button type="button" id="recommend-btn" class="btn-link recommend-btn"' +
                    'onclick="location.href=\'support/solutions?mktModelCd={{modelCode}}\'"><span>더보기</span></button>'+
            '</div>'+
            '<ul class="recommend-list" data-contents="{{modelCode}}">'+
            '{{#each (item, index) in data.recommendList}}' +
                '<li>'+
                '<a href="/support/solutions-{{item.item_id}}" title="{{item.item_title}} - 바로가기">'+
                    '{{#if index != 1}}'+
                        '<em class="flag">HOT</em>'+
                    '{{#else}}'+
                        '<em class="flag">NEW</em>'+
                    '{{/if}}'+
                    '{{item.item_title}}'+
                '</a>'+
                '</li>'+
            '{{/each}}' +
            '</ul>';


            var $selectBox = $('.own-product-wrap').find('[data-model-code="'+_data.modelCode+'"]').parent();
            var $selectCont = $selectBox.siblings('.prd-cont');
            
            
            var isHas = !!($selectCont.has(".home-links-wrap").length  || $selectCont.has(".supplies-wrap").length || $selectCont.has(".recommend-wrap").length);
            if(isHas) return;

            var url = self.$ownProductWrap.data('ajax');
            var param = {
                modelCode : _data.modelCode
            };
            lgkorUI.showLoading();
            lgkorUI.requestAjaxDataPost(url, param, function(result) {

                var data = result.data,
                    isServiceMenu = Object.keys(data).includes('serviceMenuList'),
                    isSupplies = Object.keys(data).includes('suppliesList'),
                    isRecommend = Object.keys(data).includes('recommendList'),
                    arrServiceMenuList = data.serviceMenuList instanceof Array ? data.serviceMenuList : [],
                    arrSuppliesList = data.suppliesList instanceof Array ? data.suppliesList : [],
                    arrRecommendList = data.recommendList instanceof Array ? data.recommendList : [],
                    seviceHtml = "",
                    suppliesHtml = "",
                    recommendHtml = "";


                if(result.status == 'success'){
                    
                    $selectCont.empty();

                    //서비스메뉴
                    if(isServiceMenu && arrServiceMenuList.length > 0) {
                        $selectCont.append('<div class="home-links-wrap"></div>');
                        seviceHtml = vcui.template(serviceTmpl, {data : data, modelCode : _data.modelCode});
                        $selectCont.find('.home-links-wrap').empty().html(seviceHtml); 
                    }
                    //연관소모품
                    if(isSupplies && arrSuppliesList.length > 0) {
                        var suppliesArr = vcui.array.map(data.suppliesList, function(item, index) {
                            if(item.price) {
                                item.price = item.price > 0 ? vcui.number.addComma(item.price) + "원" : '';
                            }
                            // if(item.category){
                            //     item.promotionFlag = item.category.includes('LG UP Kit') ? 'Y' : '';
                            // }
                            return item;
                        });

                        $selectCont.append('<div class="supplies-wrap"></div>');
                        suppliesHtml = vcui.template(suppliesTmpl, {suppliesList: suppliesArr, modelCode : _data.modelCode});
                        $selectCont.find('.supplies-wrap').empty().html(suppliesHtml); 
                    }
                    //추천콘텐츠
                    if(isRecommend && arrRecommendList.length > 0) {
                        $selectCont.append('<div class="recommend-wrap"></div>');
                        recommendHtml = vcui.template(recommendTmpl, {data : data, modelCode : _data.modelCode});
                        $selectCont.find('.recommend-wrap').empty().html(recommendHtml); 
                    }
                }
            });

        },
        requestQnaList: function(_data){
            
            var template = 
                '{{#each (item, index) in listData}}'+
                '<li class="item">'+
                '<a href="/support/solutions-{{item.item_id}}">'+
                    '<p><span class="icon"><img src="{{item.icon_path}}" alt="{{item.cate_name}}"></span></p>'+
                    '<p class="type">{{item.symp_name}}</p>'+
                    '<p class="title">'+
                        '<span class="txt">{{item.item_title}}</span>'+
                        '{{#if item.video_flag == "Y"}}'+
                            '<span class="icon-movie"><em class="blind">동영상 컨텐츠</em></span>'+
                        '{{/if}}'+
                    '</p>'+
                '</a>'+
                '</li>'+
                '{{/each}}';
            
            var $popularListWrap = $context.find('.popular-list-wrap');
            var subCategory = _data.subCategory;
            var url = $popularListWrap.data('ajax');
                url = url + '?cateList=' + subCategory; //"/support/cateContents.lgajax?cateList="+subCategory;
            
            lgkorUI.showLoading();
            lgkorUI.requestAjaxData(url, {}, function(result) {

                var data = result.data,
                    dateArr = data.listData instanceof Array ? data.listData : [],
                    html = "";

                if(data.resultFlag == 'Y'){
                    if(!!dateArr.length) {
                        var lng = dateArr.length > 3 ? 3 : dateArr.length;
                        //3개 노출
                        var dataObj = {listData : dateArr.splice(0,3)};
                        html += vcui.template(template, dataObj);

                        $popularListWrap.find('.popular-list').empty().html(html);   
                    }
                }
            });

        },
        requestUpdateLastModel: function(_data){
            
            var modelCode = _data.modelCode;
            var url = "/support/updateLastChoiceModel.lgajax?selectedModel="+modelCode;

            lgkorUI.showLoading();
            lgkorUI.requestAjaxData(url, {}, function(result) {
                //console.log(result.status);
            });
        },
        reReservation: {
            register : {
                date: {
                    required: true,
                    msgTarget: '.err-msg'
                },
                time: {
                    required: true,
                    msgTarget: '.err-msg'
                }
            },
            el : {
                popup                   : $('#reservationTimePopup'),
                selectedEngineer        : $('#reservationTimePopup .engineer-to-visit'),
                toggleOtherBtn          : $('#reservationTimePopup .engineer-to-visit .engineer-desc button'),
                stepEngineer            : $('#reservationTimePopup #stepEngineer'),
                date                    :  $('#reservationTimePopup .date-wrap'),
                time                    :  $('#reservationTimePopup .time-wrap-new'),
            },
            requestDate: function() {
                var self = this;
                var url = self.dateUrl,
                    param;
    
                param = self.dateParam;
    
                lgkorUI.showLoading();
                lgkorUI.requestAjaxDataPost(url, param, function(result) {
                    var data = result.data,
                        arr;
    
                    if (data.resultFlag == 'Y') {
                        arr = data.dateList instanceof Array ? data.dateList : [];

                        self.dateParam.deptCode = data.deptCode; // BTOCSITE-58397 고객지원 > 스마일+ 오픈대응

                        if (arr.length) {
                            // BTOCSITE-72562 출장접수 T/T 제공 로직 보완 진행 (일반, 우선건, 계절성)
                            var _dateList = vcui.array.map(arr, function(item, idx){
                                return item.split('/')[0];
                            });
                            self.dateList = arr;
                           
                            self.el.date.moDateCalendar('update', _dateList);
                           
                            self.el.popup.attr('tabindex', '0').focusin().vcModal();
                        }
                    } else {
                        if (data.resultMessage) {
                            
                            lgkorUI.alert("", {title: data.resultMessage});
                        }
                    }
                    lgkorUI.hideLoading();
                });
            },
            requestTime: function() {
                var self = this;
                var url = self.timeUrl,
                    param;

                param = $.extend(self.dateParam, { 
                    date : self.el.popup.find('input[name=date]').val(),
                        
                });
                
                lgkorUI.showLoading();
                lgkorUI.requestAjaxDataPost(url, param, function(result) {
                    var data = result.data,
                        arr;
    
                    if (data.resultFlag == 'Y') {
                        arr = data.timeList instanceof Array ? data.timeList : [];

                        if (arr.length) {
                            self.el.time.moTimeCalendar('update', arr).parent('.box-inner.time').show();
                        }
                    } else {
                        if (data.resultMessage) lgkorUI.alert("", {title: data.resultMessage});
                    }
                    lgkorUI.hideLoading();
                });
            },
            requestUnlock: function() {
                var self = this;
                var url = self.unlockUrl,
                    unlockParam = {
                        productCode: self.dateParam.productCode,
                        serviceType: self.dateParam.serviceType,
                        lockUserId: self.dateParam.lockUserId,
                        lockId: self.dateParam.lockId,
                    };

                $.ajax({
                    type: "POST",
                    async: true,
                    url: url,
                    data: unlockParam,
                    dataType: "json" , 
                    success: function(result){
                        if (result.data.resultFlag === 'Y') {
                            self.request();
                        }
                    },
                    error:function(){ 
                    }
                });
            },
            request : function() {
                var self = this;
                var url = self.lockUrl;
                var param = self.dateParam;
    
                lgkorUI.showLoading();
                lgkorUI.requestAjaxDataPost(url, param, function(result) {
                    var data = result.data; 
    
                    if (data.resultFlag == 'Y') {  
                        self.dateParam.lockId = data.lockId;
                    }else{
                        if (data.resultMessage) {
                            lgkorUI.alert("", {
                                title: data.resultMessage
                            });
                        }
                    }
                });
            },
            complete : function(){
                var self = this;

                var $form = $('#reservationSubmitForm');
                var url = self.submitUrl;
                var param = self.validation.getAllValues();
                    param = $.extend(param, self.dateParam);
                
                lgkorUI.showLoading();
                lgkorUI.requestAjaxDataPost(url, param, function(result) {
                    var data = result.data;

                    if (data.resultFlag == 'Y') {
                        $form.find('[name=acptNo]').val(data.acptNo);
                        $form.find('[name=selectAcptNo]').val(self.dateParam.selectAcptNo);
                        $form.find('[name=categoryNm]').val(self.dateParam.categoryNm);
                        $form.find('[name=subCategoryNm]').val(self.dateParam.subCategoryNm);
                        $form.find('[name=isMyProduct]').val(self.dateParam.isMyProduct);
                        $form.find('[name=salesModelCode]').val(self.dateParam.salesModelCode);
                        $form.find('[name=modelCode]').val(self.dateParam.modelCode);
                        $form.find('[name=userNm]').val(self.dateParam.userNm);
                        $form.find('[name=phoneNo]').val(self.dateParam.phoneNo);
                        $form.find('[name=userAddress]').val(self.dateParam.userAddress);
                        $form.find('[name=detailAddress]').val(self.dateParam.detailAddress);
                        $form.submit();
                    } else {
                        if (data.resultMessage) {
                            lgkorUI.alert("", {
                                title: data.resultMessage
                            });
                        }
                    }
                    lgkorUI.hideLoading();
                }, 'POST');
            },
            init: function(options) {
                if (!$('#reservationTimePopup').length) return;

                var self = this;
                    self.dateParam = $.extend({}, options);

                self.dateUrl = "/support/reserve/possibleReserveDate";
                self.timeUrl = "/support/reserve/possibleReserveSchedule.lgajax";
                self.unlockUrl = "/support/reserve/setEnginnerUnLockSchedule.lgajax";
                self.lockUrl = "/support/reserve/selectDefaultEngineer.lgajax";
                self.submitUrl = "/support/reserve/reserveServiceSubmit.lgajax"

                self.el.date.moDateCalendar({inputTarget: '#date'});
                self.el.time.moTimeCalendar({inputTarget: '#time'}).parent('.box-inner.time').hide(); 

                self.el.date.off('dateselected').on('dateselected', function(e, date) {
                    var _deptCode = self.dateList.find(function(item, idx){
                        return item.includes(date);
                    });

                    self.dateParam.deptCode = _deptCode.split('/')[1];

                    self.requestTime();
                }); 
                
                self.el.time.off('timeselected').on('timeselected', function() {
                    
                    self.requestUnlock();
                    
                    $.extend(self.dateParam, { 
                        date : self.el.popup.find('input[name=date]').val(),
                        time : self.el.popup.find('input[name=time]').val(),
                        
                    });
                });

                self.el.popup.on('modalhide', function() {
                    self.dateParam = {};
                    self.validation.reset();
                    self.el.date.moDateCalendar('reset');
                    self.el.time.moTimeCalendar('reset').parent('.box-inner.time').hide();
                    
                }).on('click', '.btn-group .btn-confirm', function() {
                    var result = self.validation.validate();
                    if( result.success == true) {
                    
                        self.complete();
                    }
                });
                
                vcui.require(['ui/validation'], function () {
                    self.validation = new vcui.ui.CsValidation('#reservationTimePopup', {
                        register: self.register
                    });
                });
            }
        },
        bindEvent: function() {
            var self = this;
            self.$ownProductWrap = $context.find(".own-product-wrap");
            self.$reservWrap = $context.find(".recently-reserv-wrap"); //최근 이용 서비스
            self.$reservPopup = $context.find("#reReservationPopup"); //다시 예약 팝업
            self.$reservTimePopup = $context.find("#reservationTimePopup"); //예약일시 팝업


            //보유제품
            self.$ownProductWrap.find(".product-box .btn-open").on("click", function(e){
                e.preventDefault();

                //GA태깅
                self.$ownProductWrap.find(".product-box .prd-head").each(function(index, item){
                    var gaText = $(item).attr('data-contents');
                    if(gaText.indexOf('링크') > -1){
                        $(item).attr('data-contents', gaText.replace('링크', '선택'));
                    }
                });
                //GA태깅
                var gaText = $(this).parent('.prd-head').attr('data-contents');
                $(this).parent('.prd-head').attr('data-contents', gaText.replace('선택', '링크')); 

                var activeIdx = self.$ownProductWrap.find(".product-box.open").index();
                var currIdx = $(this).parents(".product-box").index();
                var data = $(this).data();

                if(activeIdx == currIdx) {
                    // PDP가 없는 제품은 PSP로 이동
                    if(!!data.pdpUrl){
                        location.href = data.pdpUrl;
                    }else{
                        location.href = data.pspUrl;
                    }
                    return;
                }

                // self.requestQnaList(data);
                self.requestUpdateLastModel(data);
                self.requestServiceList(data);

                $(".product-box").removeClass("open").find('.prd-cont').attr('tabindex', '-1');
                $(this).parents(".product-box").addClass("open").slideDown("fast");
                $(this).parents(".product-box").find('.prd-cont').attr('tabindex', '0');

                lgkorUI.scrollTo($(this).parents(".product-box"));
            });
            
            //보유제품 전체보기
            self.$ownProductWrap.find(".btn-view-all").on("click", function(e){
                $(this).hide();
                self.$ownProductWrap.find('.product-box').show();
                self.$ownProductWrap.find(".btn-view-close").show();
            });
            
            //보유제품 접기
            self.$ownProductWrap.find(".btn-view-close").on("click", function(e){
                self.$ownProductWrap.find(".btn-view-close").hide();
                self.$ownProductWrap.find(".btn-view-all").show();
                self.$ownProductWrap.find('.product-box').each(function(index){
                    //3개 이상 숨김
                    if(index > 2) {
                        $(this).hide();
                    }
                });
            });

            //소모품 장바구니
            self.$ownProductWrap.on('click', '.btn-cart', function(e){
                e.preventDefault();
                // var sendflag = (typeflag == "PRODUCT" || typeflag == "DISPOSABLE") ? "P" : "C";
                if(!$(this).hasClass('disabled')){
                    var $this = $(this);
                    var param = {
                        "id":$this.attr('data-id'),
                        "sku":$this.attr('data-sku'),
                        "rtSeq":'',
                        "typeFlag": "P"
                    }
                    var ajaxUrl = "/mkt/commonModule/addCart.lgajax";
                    lgkorUI.requestCart(ajaxUrl, param, true);
                }
            });

            //서비스 다시 예약
            self.$reservWrap.find('.btn-re-reserv').on("click", function(e){
                e.preventDefault();
                self.$reservWrap.find('.btn-re-reserv').removeClass('pop-opened');
                $(this).addClass('pop-opened');

                self.reReservData =  $(this).data();
                var svcType = self.reReservData.svcType;
                var txtSvc = "";

                switch(svcType) {
                    case "A":
                        txtSvc = "<em>출장</em>서비스를"
                        break;
                    case "B":
                        txtSvc = "<em>센터</em>방문을"
                        break;
                    default:
                        txtSvc = "<em>상담</em>예약을"
                        break;
                }
                self.$reservPopup.find('dt .txt-cate').text(self.reReservData.subCategoryNm);
                self.$reservPopup.find('dt .txt-topic').text(self.reReservData.topicNm);
                self.$reservPopup.find('dt .txt-svc').html(txtSvc);
                self.$reservPopup.find('dd .txt-topic').html(self.reReservData.topicNm + " &gt; " + self.reReservData.subTopicNm + " &gt; " + self.reReservData.threeTopicNm);

                var url = "/support/reserveResult/selectReserveDetailForReapply.lgajax";
                var param = {
                    rcptName: self.reReservData.rcptName,
                    acptNo: self.reReservData.acptNo,
                    acptEmp: $('#acptEmp').val(),
                    route: $('#route').val(),
                };
                
                lgkorUI.showLoading();
                lgkorUI.requestAjaxDataPost(url, param, function(result) {
                    var data = result.data;

                    if (result.status == 'success') {

                        $.extend(self.reReservData, data);

                        //접수번호
                        data = $.extend(data, {
                            selectAcptNo: self.reReservData.acptNo
                        });         
                        self.reReservation.init(data); //일시 예약
                        self.$reservPopup.attr('tabindex', '0').focusin().vcModal();
                        self.$reservPopup.find("[id=reservType01]").prop('checked', true);
                        
                    }
                    
                    lgkorUI.hideLoading();
                });

            });


            //서비스 다시 예약 팝업 - 확인
            self.$reservPopup.find(".btn-re-reservation").on("click", function(e){
                var val = self.$reservPopup.find("[name=reservType]:checked").val(),
                    svcType = self.reReservData.svcType,
                    href;

                var obj = {
                    selectAcptNo: self.reReservData.acptNo,
                    mktModelCd: self.reReservData.modelCode,
                    category: self.reReservData.category,
                    subCategory: self.reReservData.subCategory,
                    categoryNm: self.reReservData.categoryNm,
                    subCategoryNm: self.reReservData.subCategoryNm,
                    productCode: self.reReservData.productCode,
                }
                
                var setTypeFlag = (self.reReservData.setType != undefined);

                if(setTypeFlag){
                    for (var prop in self.reReservData) {
                        if(prop == 'setType'){
                            obj.setType = self.reReservData[prop];
                        }
                        if(prop == 'setTypeDesc'){
                            obj.setTypeDesc = self.reReservData[prop];
                        }
                        if(prop == 'fan'){
                            obj.fan = self.reReservData[prop];
                        }
                        if(prop == 'addFan'){
                            obj.addFan = self.reReservData[prop];
                        }
                        if(prop == 'setTypeAdd'){
                            obj.setTypeAdd = self.reReservData[prop];
                        }
                    }
                }
                
                var param = '';
                Object.keys(obj).forEach(function(key, index){
                    param = param + (index === 0 ? '?' : '&') + key + '=' + obj[key];
                });

                switch(svcType) {
                    case "A":
                        href = "/support/service-engineer-reservation"+param+'&cstFlag=Y';
                        break;
                    case "B":
                        href = "/support/visit-center-reservation"+param+'&cstFlag=Y';
                        break;
                    default:
                        href = "/support/request-call-reservation"+param+'&cstFlag=Y';
                        break;
                }

                if(val == 'Y'){
                    self.$reservPopup.attr('tabindex', '-1').vcModal('close');
                    self.reReservation.requestDate(); //일시 예약
                }else{
                    location.href = href;
                }
             
            });

            self.$reservPopup.find('.ui_modal_close').on("click", function(e){
                //BTOCSITE-71268 고객지원 > 웹접근성
                self.$reservPopup.attr('tabindex', '-1').focusout();
                setTimeout(function(){
                    self.$reservWrap.find('.btn-re-reserv.pop-opened').focus();
                }, 500);
            });
            self.$reservTimePopup.find('.ui_modal_close').on("click", function(e){
                //BTOCSITE-71268 고객지원 > 웹접근성
                self.$reservTimePopup.attr('tabindex', '-1').focusout();
                setTimeout(function(){
                    self.$reservWrap.find('.btn-re-reserv.pop-opened').focus();
                }, 200);
            });

            //BTOCSITE-83209 고객지원 > 메인 > 마이영역 수정
            $('#serviceBtn').on("click", function(e){
                $('#servicePopup').show().addClass('active');
                if($('#servicePopup').find('.btn-close').hasClass('close'))
                    $('#servicePopup').find('.btn-close').removeClass('close').addClass('open');
                var floatingWrap = document.querySelector(".floating-wrap");
                var btnFloatingWrap = document.querySelector(".btn-floating-wrap");
                var areaH = (vcui.detect.isApp)?80:100;

                if (floatingWrap && btnFloatingWrap) {
                    if (window.breakpoint.isMobile) {
                        areaH += self.$actionSheetCallBtn.outerHeight();
                        floatingWrap.style.setProperty('--bottom', areaH + 'px');
                        btnFloatingWrap.style.setProperty('--bottom', areaH + 'px');
                    } else {
                        floatingWrap.style.removeProperty('--bottom');
                        btnFloatingWrap.style.removeProperty('--bottom');
                    }
                }
                // BTOCSITE-83209 고객지원 > 메인 > 마이영역 수정
                $('#servicePopup').attr('tabindex', '0');
                e.preventDefault();
            });
            // BTOCSITE-83209 고객지원 > 메인 > 마이영역 수정
            $('#servicePopup').find('.compare-title').on("click", function(e) {
                if($('#servicePopup').hasClass('active')){
                    $('#servicePopup').removeClass('active');
                    $('#servicePopup').find('.btn-close').removeClass('open').addClass('close');
                    $('#servicePopup').find('.compare-title').removeAttr('tabindex');
                    $('#servicePopup').find('.btn-confirm.btn.pink').attr('tabindex', '-1');
                    for(var i=0; i<$('#servicePopup').find('.list-wrap .item').length; i++) {
                        $('#servicePopup').find('.list-wrap .item a').eq(i).attr('tabindex', '-1');
                    }
                }else{
                    $('#servicePopup').addClass('active');
                    $('#servicePopup').find('.btn-close').removeClass('close').addClass('open');
                    $('#servicePopup').find('.btn-confirm.btn.pink').removeAttr('tabindex');
                    for(var i=0; i<$('#servicePopup').find('.list-wrap .item').length; i++) {
                        $('#servicePopup').find('.list-wrap .item a').eq(i).removeAttr('tabindex');
                    }
                    $('#servicePopup').attr('tabindex', '0');
                }
                e.preventDefault();
            });
        },
        initialize: function(){
            var _this = this;
            
            //_this.loginTooltip();
            _this.slide.init(); // 공지사항
            _this.keyword.init();
            _this.modal.init();
            _this.center.init();
            _this.bindEvent();


            if (lgkorUI.searchParamsToObject('smq') == 'Y') {
                lgkorUI.setAcecounter('www.lge.co.kr/acecount/mainThinqView.do', '/acecount/mainThinqViewm.do');
            }
        }
    }
    

    $(window).ready(function(){
        supportHome.initialize();    

        global[script.name] = true; // 중복 로딩 체크
        
    })
})(window);